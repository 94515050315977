import React from "react";
import { graphql, Link } from "gatsby";

import { openPopupWidget } from "react-calendly";
// import { List, MicroStar } from "@dadoagency/gatsby-plugin-trustpilot-widget";

import Layout from "../components/Layout";
// import Hero from "../components/Hero";
import { Hero, Section } from "../components/bulma";
// import Slideshow from '../components/Slideshow';
import Blurbs from "../components/Blurbs";
import Pitch from "../components/Pitch";
import ContentSection from "../components/ContentSection";
// import FeaturedBlog from "../components/FeaturedBlog";
import Faq from "../components/Faq";
import CTA from "../components/CTA";
// import Tags from "../components/Tags";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

import PreviewCompatibleImage from "../components/PreviewCompatibleImage";
import SEO from "../components/SEO/SEO";

import trustpilot from "../img/trustpilot-45stars.png";
import stars5 from "../img/stars-5.svg";
import phones from "../img/phones.png";

import reviews from "../config/reviews";

const url = "https://calendly.com/fastlabs/free-consultation";

export default function IndexPage({ data }) {
  const { markdownRemark: page } = data;
  const {
    image,
    title,
    description,
    heading,
    subheading,
    sections,
    blurbs,
    pitch,
    showcase,
    final,
  } = page.frontmatter;

  return (
    <Layout>
      <IndexPageTemplate
        title={title}
        description={description}
        image={image}
        heading={heading}
        subheading={subheading}
        blurbs={blurbs}
        pitch={pitch}
        sections={sections}
        showcase={showcase}
        final={final}
      />
    </Layout>
  );
}

export const IndexPageTemplate = ({
  image,
  title,
  description,
  heading,
  subheading,
  pitch,
  sections,
  blurbs,
  showcase,
  final,
}) => {
  const onClick = () => openPopupWidget({ url });

  return (
    <div>
      <SEO
        title={title}
        description={description}
        // image={image.childImageSharp.sizes.src}
        pathname="/"
        article
      />

      <Hero>
        <div className="columns is-vcentered">
          <div className="column is-6-desktop p-6">
            <h1 className="title is-1 is-hidden-mobile  has-text-weight-bold is-spaced">
              {heading}
            </h1>
            <h1 className="title is-hidden-desktop is-size-2-mobile has-text-weight-bold is-spaced">
              <br />
              {heading}
            </h1>
            <h2 className="subtitle  is-size-4-mobile is-size-4">
              {subheading}
            </h2>

            <div className="buttons">
              <p className="control">
                <button
                  className="button is-primary is-medium is-rounded"
                  onClick={onClick}
                >
                  <strong>TALK TO EXPERT</strong>
                </button>
              </p>

              <p className="control">
                <Link
                  to="/why-business-needs-mobile-app"
                  className="button is-text is-medium is-rounded"
                >
                  Why build a mobile app?
                </Link>
              </p>
              <p>Book a free, no-obligation consultation now!</p>
            </div>
            <br />
            <br />
            <div className="is-hidden-mobile">
              <a
                href="https://www.trustpilot.com/review/fastlabs.co"
                target="_blanc"
              >
                <img src={trustpilot} style={{ height: 50 }} />
              </a>
            </div>
          </div>
          <div className="column is-6">
            <PreviewCompatibleImage imageInfo={image} />
          </div>
        </div>
        <Blurbs box items={blurbs} />
      </Hero>

      <Section>
        <Pitch pitch={pitch} />
      </Section>

      <Section>
        <div className="notification p-6">
          <div className="columns is-vcentered">
            <div className="column">
              <p className="title is-spaced">
                Financial Technology, Real Estate, Auto Dealerships, Social
                Media?
              </p>
              <p className="subtitle is-4">
                We have expertise in your industry.
              </p>
            </div>
            <div className="column">
              <img src={phones} />
            </div>
          </div>
        </div>
      </Section>

      <Section>
        <ContentSection items={sections} box />
      </Section>

      <Section>
        <p className="title is-1 has-text-centered">Case Studies</p>
        <div className="notification">
          <Carousel
            autoPlay
            showStatus={false}
            showThumbs={true}
            interval={5000}
            infiniteLoop={true}
          >
            {showcase.map(({ title, text, image }) => (
              <div className="columns m-6-desktop p-6-desktop is-vcentered">
                <div className="column">
                  <p className="title is-1">{title}</p>
                  <p className="subtitle is-3">{text}</p>
                </div>
                <div className="column is-5">
                  <figure className="image">
                    <PreviewCompatibleImage imageInfo={image} />
                  </figure>
                </div>
              </div>
            ))}
          </Carousel>
        </div>
      </Section>

      <Section>
        <p className="title is-1 has-text-centered m-6">What our clients say</p>

        <div className="columns is-multiline mt-6">
          {reviews.map(review => (
            <div className="column is-3">
              <div className="notification">
                <p className="title is-4">
                  {review.title} <img src={stars5} style={{ height: 20 }} />
                </p>
                <p className="subtitle is-6">{review.text}</p>

                <p className="title is-6 mt-1">{review.author}</p>
              </div>
            </div>
          ))}
        </div>
      </Section>

      {/* <Section>
        <Faq items="6" size="3" category="general" />
      </Section> */}

      <Section>
        <CTA />
      </Section>

      {/* <Section>
      <h2 className="title is-size-1 has-text-centered">All about payments</h2>
      <h3 className="subtitle has-text-centered">
        Tips, tricks and knowledge every small business needs today.
      </h3>
      <br />
      <FeaturedBlog box size="2" items="4" />
      <Tags />
    </Section> */}
      {/* <Section>
			<DigestRoll box size="2" items="5" />
		</Section> */}
    </div>
  );
};

export const indexPageQuery = graphql`
  query IndexPage {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        description
        heading
        subheading
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
        pitch {
          title
          text
          cta
          link
        }
        blurbs {
          #   image {
          #     childImageSharp {
          #       fluid(maxWidth: 240, quality: 64) {
          #         ...GatsbyImageSharpFluid_withWebp_tracedSVG
          #       }
          #     }
          #   }
          title
          text
        }
        sections {
          title
          text
          color
          side
          image {
            childImageSharp {
              fluid(maxHeight: 500, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
        showcase {
          title
          text
          image {
            childImageSharp {
              fluid(maxHeight: 500, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
        cta {
          title
          text
          label
        }
      }
    }
  }
`;
